
.App {
  text-align: center;
  font-family:roboto;
}

.App-header {
  background: url("./images/stairs2.jpg") no-repeat top left fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family:roboto;
}

.App-top {
  position: fixed;
  top: 0px;
  left: 200px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(240,240,240,0.1);
}

.App-bottom {
  position: absolute;
  bottom: 10px;
  left: 200px;
  padding: 10px;
  background-color: rgba(240,240,240,0.4);
}

.App-link {
  color: #61dafb;
}

